<template>
  <div v-for="(address, index) in innerAddresses" :key="address.id">
    <div
      v-if="addressIdToEdit !== address.id"
      class="group flex items-center justify-between p-6 rounded border mb-4 hover:bg-gray-50"
      :data-cy="`address_${index}`"
      :key="address.id"
    >
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <icon-base
            icon="home"
            :icon-color="UTIL_COLORS.DEFAULT"
            class="mr-3"
          />
          <p class="mr-3">
            {{
              isAddressEmpty(address)
                ? $t("COMMON.NO_DATA")
                : formatAddress({
                    address_line: address?.address_line || "",
                    address_line2: address?.address_line2 || "",
                    city: address?.city || "",
                    state: address?.state || "",
                    country: address?.country || "",
                    zip: address?.zip || ""
                  })
            }}
          </p>
        </div>
        <span
          v-if="address.is_primary"
          class="flex items-center justify-center bg-blue-100 text-blue-500 h-5 w-5 text-xs font-medium rounded-full"
        >
          {{ $t("COMMON.PRIMARY_SHORTCUT") }}
        </span>
      </div>
      <div class="flex items-center opacity-0 group-hover:opacity-100">
        <icon-base
          :role="`editBtn_${index}`"
          icon="edit"
          class="mr-3 cursor-pointer"
          @click="editAddress(address.id || null)"
        />
        <icon-base
          v-if="!address.is_primary && canDelete"
          icon="delete"
          class="cursor-pointer text-disabled-medium"
          @click="deleteAddress(address)"
        />
      </div>
    </div>

    <address-fields
      v-if="addressIdToEdit === address.id"
      v-model:address="innerAddresses[index]"
      :key="address.id"
      @close="handleClose"
      @update-primary-value="handlePrimaryChange"
    />
  </div>
  <button
    class="flex w-full items-center justify-center p-5 mb-6 bg-blue-50 text-blue-500 text-xs font-medium rounded hover:bg-blue-100"
    @click.prevent="addNewAddress"
  >
    + {{ $t("DEALS.DEAL_PROGRESS.BUSINESS_INFO.ADD_NEW_ADDRESS") }}
  </button>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, watch, computed } from "vue";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import type { IAddress } from "@/models/common";
import { formatAddress } from "@/helpers/formatting";
import type { DeleteRecord } from "@/models/workflows";
import { UTIL_COLORS } from "@/helpers/constants";

import AddressFields from "@/views/deals/components/AddressFields.vue";

const props = defineProps({
  addresses: {
    type: Array as PropType<(IAddress | DeleteRecord)[]>,
    required: true
  },
  canDelete: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits<{
  (eventName: "update:addresses", adresses: (DeleteRecord | IAddress)[]): void;
}>();

const addressIdToEdit = ref<number | string | null>(null);
const innerAddresses = ref<IAddress[]>(
  cloneDeep(
    props.addresses.filter((item) => !("_delete" in item)) as IAddress[]
  )
);
const addressesToDelete = ref<DeleteRecord[]>([]);

const addressToEdit = computed(() =>
  innerAddresses.value.find((address) => address.id === addressIdToEdit.value)
);

const handleClose = () => {
  addressIdToEdit.value = null;
};

const addNewAddress = () => {
  const newAddressId = Number(Math.random().toString().split(".").join(""));
  innerAddresses.value.push({
    id: newAddressId,
    address_line: "",
    address_line2: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    is_primary: props.addresses.length === 0 ? true : false
  });
  addressIdToEdit.value = newAddressId;
};

const handlePrimaryChange = () => {
  innerAddresses.value.forEach((addrs) => {
    if (addrs.id !== addressToEdit.value?.id) {
      addrs.is_primary = false;
    }
  });
};

const editAddress = (id: number | null) => {
  addressIdToEdit.value = id;
};

const deleteAddress = (address: IAddress) => {
  innerAddresses.value.splice(innerAddresses.value.indexOf(address), 1);
  if (address.id) {
    addressesToDelete.value.push({ id: address.id, _delete: true });
  }
};

const isAddressEmpty = (address: IAddress): boolean =>
  !address?.address_line &&
  !address?.address_line2 &&
  !address?.city &&
  !address?.country &&
  !address?.state &&
  !address?.zip;

watch(
  innerAddresses,
  () =>
    emit("update:addresses", [
      ...innerAddresses.value,
      ...addressesToDelete.value
    ]),
  {
    deep: true
  }
);

watch(
  () => props.addresses,
  (newVal) => {
    if (
      isEqual(newVal, [...innerAddresses.value, ...addressesToDelete.value])
    ) {
      return;
    }
    innerAddresses.value = props.addresses.filter(
      (item) => !("_delete" in item)
    ) as IAddress[];
  },
  {
    deep: true
  }
);
</script>
